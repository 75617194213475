import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import EnergyConsumedChart from "./Charts/EnergyConsumedChart";
import { ChartSectionContainer } from "./Components/StyledComponent";
import TotalChargingTimeChart from "./Charts/TotalChargingTimeChart";
import EnergyEfficiencyChart from "./Charts/EnergyEfficiencyChart";
import ChargingFrequencyChart from "./Charts/ChargingFrequencyChart";
import dayjs from 'dayjs';
import { rangePresets } from "../../Static/Data/ChargingAnalytics/ChargingAnalytics";
import { geDateRangesForCk, getDatePickerDate, getPreviousMonthDate } from "../../Helper/DatePicker/DateConverter";

function ChartsSection({ pagesContent }) {
  const calendarStartDate = useSelector((state) => state.ChargingAnalyticsStartDate.value) || geDateRangesForCk()[0];
  const calendarEndDate = useSelector((state) => state.ChargingAnalyticsEndDate.value) || geDateRangesForCk()[1];
  const selectedFilters = useSelector((state) => state.SelectedFilterValues.value)
  const [granularity, setGranularity] = useState({granularity: 'weekly'})
  const [cardSubtitleTimePeriodText, setCardSubtitleTimePeriodText] = useState("")

  useEffect(() => {
    const startDate = dayjs(calendarStartDate);
    const endDate = dayjs(calendarEndDate);
    const differenceInDays = endDate.diff(startDate, 'day');
    setGranularity({granularity: differenceInDays > 31 ? 'monthly' : 'weekly'})
    calculateCardSubtitleTimePeriodText()
  },[calendarStartDate, calendarEndDate])

  const calculateCardSubtitleTimePeriodText = () => {
    const matchedPreset = rangePresets.find((preset) => {
      return preset.value[0].format("YYYY-MM-DD") === calendarStartDate && preset.value[1].format("YYYY-MM-DD") === calendarEndDate
    })

    // if (matchedPreset) {
    //   setCardSubtitleTimePeriodText(`• ${matchedPreset?.label}`)
    // } else {
    //   setCardSubtitleTimePeriodText(`• ${dayjs(calendarStartDate).format("D MMM, YYYY")} - ${dayjs(calendarEndDate).format("D MMM, YYYY")}`)
    // }
  }

  return (
    <ChartSectionContainer>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <EnergyConsumedChart cardSubtitleTimePeriodText={cardSubtitleTimePeriodText} filters={selectedFilters} startDate={calendarStartDate} endDate={calendarEndDate} granularity={granularity}/>
        </Grid>
        <Grid item xs={6}>
          <EnergyEfficiencyChart cardSubtitleTimePeriodText={cardSubtitleTimePeriodText} filters={selectedFilters} startDate={calendarStartDate} endDate={calendarEndDate} granularity={granularity}/>
        </Grid>
        <Grid item xs={6}>
          <TotalChargingTimeChart cardSubtitleTimePeriodText={cardSubtitleTimePeriodText} filters={selectedFilters} startDate={calendarStartDate} endDate={calendarEndDate} granularity={granularity}/>
        </Grid>
        <Grid item xs={6}>
          <ChargingFrequencyChart cardSubtitleTimePeriodText={cardSubtitleTimePeriodText} filters={selectedFilters} startDate={calendarStartDate} endDate={calendarEndDate} granularity={granularity}/>
        </Grid>
      </Grid>
    </ChartSectionContainer>
  )
}

export default ChartsSection
