import React from "react"
import { useSelector } from "react-redux";
import { KPIContainer } from "./Components/StyledComponent"
import KPICard from "./Components/KPICard";
import { geDateRangesForCk } from "../../Helper/DatePicker/DateConverter";

function KPISection({ pagesContent }) {
  const calendarStartDate = useSelector((state) => state.ChargingAnalyticsStartDate.value) || geDateRangesForCk()[0];
  const calendarEndDate = useSelector((state) => state.ChargingAnalyticsEndDate.value) || geDateRangesForCk()[1];

  return (
    <KPIContainer>
      {pagesContent?.pageMeta?.availableKPIs?.map(kpiData => <KPICard metricName={kpiData.name} startDate={calendarStartDate} endDate={calendarEndDate} decimalPlaces={kpiData.decimalPlaces}/>)}
    </KPIContainer>
  )
}

export default KPISection
